<template>
  <loader v-if="isLoading"/>
  <div
    v-else
    v-html="reportContent"
    class="container container__report"
  ></div>
</template>

<script>
import Loader from '@/components/Loader'
import { mapActions } from 'vuex'

export default {
  name: 'Report',

  props: ['report_id', 'report_hash'],

  components: {
    Loader
  },

  data: () => ({
    isLoading: true,
    reportContent: ''
  }),

  methods: {
    ...mapActions(['fetchReport'])
  },

  async mounted () {
    try {
      this.reportContent = await this.fetchReport({
        id: this.report_id,
        hash: this.report_hash
      })
    }
    catch (ex) {
      this.reportContent = '<b>При загрузке отчёта произошла ошибка, попробуйте позднее</b>'
    }
    finally {
      this.isLoading = false;
    }

  }
}
</script>

<style scoped>
  .container__report {
    padding: 30px 0;
    text-align: left;
  }
</style>
